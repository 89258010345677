<template>
  <div>
    <div class="d-flex align-items-baseline mb-6">
      <label class="text-dark font-weight-bolder font-size-h4 lh-16">
        {{ $t("meeting_rooms.Meeting_Rooms") }}
      </label>
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
      <span class="text-dark-50 font-weight-bolder font-size-sm">
        {{ $t('meeting_rooms.Amount', {amount: roomsTotalCount}) }}
      </span>
    </div>
    <div class="card card-custom">
      <div class="card-body p-6">
        <label>{{ $t("meeting_rooms.Date") }}:</label>
        <div class="d-flex date-btn-list">
          <label
            class="radio-btn mr-2"
            :class="{'active': filter.date.length === 0 || filter.date === moment().startOf('day').unix()}"
            @click="setNewDate('today')"
          >
            <input type="radio">
            <span class="btn font-weight-bolder">
              {{ $t("meeting_rooms.Today") }}
              <span> – </span>
              {{ moment().format('DD.MM.YYYY, dd') }}
            </span>
          </label>

          <label
            class="radio-btn mr-2"
            :class="{'active': filter.date === moment().add(1,'days').startOf('day').unix()}"
            @click="setNewDate('tomorrow')"
          >
            <input type="radio">
            <span class="btn font-weight-bolder">
              {{ $t("meeting_rooms.Tomorrow") }}
              <span> – </span>
              {{ moment().add(1,'days').format('DD.MM.YYYY, dd') }}
            </span>
          </label>

          <label
            class="radio-btn mr-2"
            :class="{ 'active': filter.date === moment().add(2,'days').startOf('day').unix() }"
            @click="setNewDate('after_tomorrow')"
          >
            <input type="radio">
            <span class="btn font-weight-bolder">
              {{ $t("meeting_rooms.Day_After_Tomorrow") }}
              <span> – </span>
              {{ moment().add(2,'days').format('DD.MM.YYYY, dd') }}
            </span>
          </label>

          <label
            class="radio-btn radio-btn-date"
            :class="{ 'active': filter.date.length !== 0 && filter.date === devDate.pickerDate }"
            @click="showPicker = true"
          >
            <input type="radio">
            <span class="btn font-weight-bolder">
              {{ $t("meeting_rooms.Another_Date") }}

              <span class="pl-2" v-if="devDate.anotherDate.length <= 0">
                <font-awesome-icon icon="chevron-down" class="icon-sm"/>
              </span>
              <span v-else> – </span>

              {{ devDate.anotherDate }}

              <date-picker
                v-model="filter.date"
                :append-to-body="true"
                input-class="radio-btn-date"
                :open.sync="showPicker"
                @input="changeDatePicker"
                valueType="X"
                format="DD.MM.YYYY, dd"
                :disabled-date="disabledBeforeToday"
                :lang="$t('data-piker')"
              />
            </span>
          </label>
        </div>

        <div class="separator separator-solid border-light-primary my-5"></div>

        <div class="row mb-6">
          <div class="col-auto">
            <label>{{ $t("meeting_rooms.Space") }}:</label>
            <select class="form-control w-200px" v-model="filter.location_ids" @change="defaultPagination">
              <option :value="''">{{ $t('meeting_rooms.All') }}</option>
              <option v-for="item in locations" :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>

          <div class="col-auto">
            <label>{{ $t("meeting_rooms.Capacity") }}:</label>
            <select class="form-control w-200px" v-model="filter.seats" @change="defaultPagination">
              <option :value="null">{{ $t('meeting_rooms.No_Matter') }}</option>
              <option value="1-5">1-5</option>
              <option value="6-10">6-10</option>
              <option value="11-30">11-30</option>
              <option value="30-500">{{ $t('meeting_rooms.More_Than', {n: 30}) }}</option>
            </select>
          </div>

          <div class="col-auto">
            <label>{{ $t("meeting_rooms.Sort") }}:</label>
            <select class="form-control" v-model="filter.sort">
              <option value="sort">{{ $t('meeting_rooms.By_Oder') }}</option>
              <option value="cost">{{ $t('meeting_rooms.By_Cost') }}</option>
              <option value="id">{{ $t('meeting_rooms.By_Id') }}</option>
            </select>
          </div>
        </div>

        <div class="separator separator-solid border-light-primary my-5"></div>

        <label>{{ $t("meeting_rooms.Facilities") }}:</label>
        <div class="d-flex flex-row checkbox-list flex-wrap">
          <label class="d-inline-block checkbox-btn mr-5" v-for="item in equipment" :key="item.id">
            <input type="checkbox" :id="item.key" :value="item.id" v-model="filter.equipment" @change="defaultPagination">
            <span class="btn radio-btn font-weight-bolder"
                  :class="{ 'active': filter.equipment.find(i => i === item.id) }"
            >
              {{ item.name }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="row" v-if="loaded">
      <div class="col-12" v-for="room in rooms" :key="room.id">
        <div class="card card-custom mt-8" >
          <div class="card-body p-6">
            <div class="d-flex">

              <div class="col-md-auto px-0 mr-4">
                <div class="room-image">
                  <img :src="setImgByScreenWidth(room)" alt="">
                </div>
              </div>

              <div class="col-md-8">
                <div class="room-details">
                  <label class="text-dark text-hover-primary font-weight-bolder font-size-h2 lh-20">
                    <router-link
                        :to="{ name: 'Room',
                          params: { id: room.id },
                          query: {
                            time: timestamp,
                            from: fromTime !== 0 ? fromTime : +moment(timestamp).add(2, 'hours').startOf('hour').unix(),
                            to: toTime !== 0 ? toTime : +moment(timestamp).add(3, 'hours').startOf('hour').unix()
                          }
                        }"
                        class="text-primary"><span @click="setTimestamp">{{ room.name }}</span></router-link>
                  </label>

                  <div>
                    <font-awesome-icon icon="map-marker-alt" class="mr-2"/>
                    <span class="text-dark font-weight-bolder">
                      {{ room.location.name }}
                      <span style="color: #a5a5a5;"> · </span>
                      {{ room.location.address }}
                      <span style="color: #a5a5a5;"> · </span>
                      {{ $t("meeting_rooms.Floor", {floor: room.floor}) }}
                    </span>
                  </div>

                  <span class="d-block mt-4">
                    <span
                      class="label label-light-success label-inline font-weight-bolder mr-3 mb-1"
                      style="border-radius: 10px; font-size: 11px;"
                      v-for="item in room.equipment"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </span>
                  </span>

                  <div class="d-flex mt-4">
                    <span class="mr-6">
                      <div class="font-size-h2 text-dark">
                        {{ $t("meeting_rooms.Person", {person: room.seats}) }}.
                      </div>
                      <label class="text-muted font-weight-bold">
                        {{ $t("meeting_rooms.Capacity") }}
                      </label>
                    </span>

                    <span>
                      <div class="font-size-h2 text-dark">
                        {{ $t("meeting_rooms.Hour", {hour: room.rate.cost + room.rate.currency.symbol}) }}.
                      </div>
                      <label class="text-muted font-weight-bold">
                        {{ $t("meeting_rooms.The_Cost") }}
                      </label>
                    </span>
                  </div>
                  <TimeLine v-if="loaded"
                            :roomData="room"
                            :freeRoomTimeData="getFreeRoomTimeById(room.id)"
                            :timeStamp="timestamp"
                            :isRoom="false"
                  ></TimeLine>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 d-flex flex-wrap pt-5 flex-column-fluid">
            <div v-if="roomsTotalCount && (maxPage > 1)" class=" mx-auto center-block">
              <a v-if="filter.page > 1" @click="filter.page = 1; scrollToTop()"
                 class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-back icon-xs"></i></a>

              <a v-if="filter.page > 1" @click="filter.page = filter.page - 1; scrollToTop()"
                 class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page - 1}}</a>
              <a class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1">{{filter.page}}</a>
              <a v-if="filter.page < roomsTotalCount / filter['per-page']" @click="filter.page = filter.page + 1; scrollToTop()"
                 class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page + 1}}</a>

              <a v-if="filter.page < maxPage" @click="filter.page = maxPage; scrollToTop()"
                 class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-10" v-if="!loaded">
      <div v-for="index in 3" class="col-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-body pt-4 d-flex flex-column justify-content-between">
            <PuSkeleton height="20px"></PuSkeleton>
            <PuSkeleton :count="2" width="80%"></PuSkeleton>
            <br>
            <PuSkeleton width="80%"></PuSkeleton>
            <PuSkeleton :count="3"></PuSkeleton>
            <br>
            <PuSkeleton height="20px"></PuSkeleton>
            <PuSkeleton :count="1" width="80%"></PuSkeleton>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>

    <!-- Modal for mobile devices -->
    <AppMobileModal/>
    <!-- END Modal for mobile devices -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import TimeLine from '@/components/core/Timeline';
import AppMobileModal from "@/components/core/AppMobileModal";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import Api from "@/plugins/api";

export default {
  components: { DatePicker, TimeLine, AppMobileModal },
  computed: {
    ...mapGetters( `roomsServices`, {
      loaded: `loaded`,
      locations: `locations`,
      equipment: `equipment`,
      perPage: `perPage`,
      roomsTotalCount: `totalCount`,
      rooms: `rooms`,
      freeRoomTime: `freeRoomTime`,
      // bookedRooms: `bookedRooms`,
    }),
  },
  data() {
    return {
      showPicker: false,
      devDate: {
        anotherDate: '',
        pickerDate: '',
      },
      maxPage: 1,
      filter: {
        date: '',
        city_id: 1,
        coworking_ids: null,
        location_ids: '',
        seats: null,
        equipment: [],
        // from: "",
        // to: "",
        page: 1,
        "per-page": 10,
        sort: 'sort',
        expand: 'coworking,location',
      },
      timestamp: 0,
      fromTime: 0,
      toTime: 0
    };
  },
  created() {
    // this.filter.from = moment().subtract(7, "d").format("DD.MM.YYYY");
    // this.filter.to = moment().format("DD.MM.YYYY");
  },
  mounted: function () {
    this.timestamp = (+moment().subtract(0, 'days').startOf('day') + Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000;
    // this.LocationList();
    this.EquipmentList();
    this.fetch();
    // this.GetBookedRooms({ filter: this.filter, timestamp: this.timestamp });
  },
  watch: {
    filter: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
    rooms: {
      handler() {
        this.items_count()
      },
      deep: true,
    },
    // rooms: {
    //   handler() {
    //     this.booked = {};
    //     this.rooms.forEach(room => {
    //       this.booked[room.id] = room.booked;
    //     });
    //   }
    // },
    // timestamp: {
    //   handler() {
    //     let ids = this.rooms.map(r => r.id);
    //     this.booked = {};
    //     this.getMeetingRoomsBooked(ids, this.timestamp).subscribe(result => {
    //       for (const item of result) {
    //         if (!this.booked[item['resource_id']]) {
    //           this.booked[item['resource_id']] = [];
    //         }
    //         this.booked[item['resource_id']].push(item);
    //       }
    //       for (const item of this.rooms) {
    //         item.booked = this.booked[item.id];
    //       }
    //     });
    //   }
    // }
  },
  methods: {
    ...mapActions( `roomsServices`, [
      // `LocationList`,
      `EquipmentList`,
      // `GetBookedRooms`
    ]),
    scrollToTop() {
      window.scrollTo(0,0);
    },
    setImgByScreenWidth(room) {
      return room.photos[0].url.replace('300x-', '600x-')
    },
    getFreeRoomTimeById(roomId) {
      return this.freeRoomTime.filter(item => {
        return item.resource_id === roomId;
      })
    },
    items_count: function () {
      this.maxPage = Math.ceil(this.roomsTotalCount / this.perPage)
      return this.items ? Object.keys(this.items).length : 0;
    },
    setNewDate(string) {
        if (string === 'today') {
          this.filter.date = moment().startOf('day').unix();
        } else if (string === 'tomorrow') {
          this.filter.date = moment().add(1,'days').startOf('day').unix();
        } else if (string === 'after_tomorrow') {
          this.filter.date = moment().add(2,'days').startOf('day').unix();
        }
    },
    fetch: function () {
      let key = 'coworkingId_' + localStorage.getItem('currentUserId');
      let coworkingId = localStorage.getItem(key) || '';
      if (coworkingId) this.filter.coworking = coworkingId;
      this.$store.dispatch("roomsServices/LocationList", this.filter).then(res => {
        if (res && res.length > 0) {
          this.$store.dispatch("roomsServices/RoomsInit", this.filter)
              .then(() => {
                // console.log("rooms: " + this.rooms.map(item => item.id));
                if (this.rooms.length > 0) {
                  if (this.filter.date !== '') {
                    let ids = this.rooms.map(item => item.id);
                    let dateWithTimezone = (+moment.unix(this.filter.date) + Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000;
                    this.timestamp = dateWithTimezone;
                    this.fromTime = moment.unix(dateWithTimezone).add(9, 'hours').startOf('hour').unix();
                    this.toTime = moment.unix(dateWithTimezone).add(10, 'hours').startOf('hour').unix();
                    this.$store.dispatch("roomsServices/GetFreeTime", { ids: ids.toString(), from: dateWithTimezone });
                  } else if (this.filter.date === '') { //  && this.filter.location_ids
                    let ids = this.rooms.map(item => item.id);
                    let dateWithTimezone = (+moment.unix(moment().startOf('day').unix()) + Math.abs(moment.parseZone().utcOffset() * 60) * 1000) / 1000;
                    this.timestamp = dateWithTimezone;
                    this.$store.dispatch("roomsServices/GetFreeTime", {
                      ids: ids.toString(),
                      from: dateWithTimezone
                    });
                  }
                  this.items_count();
                } else {
                  return false;
                }
              });
        }
      });
      // this.$store.dispatch("roomsServices/GetBookedRooms", { filter: this.filter, timestamp: this.timestamp });
    },
    moment: function () {
      return moment();
    },
    disabledBeforeToday: function (date) {
      return date < moment().add(3, 'days').startOf('day');
    },
    changeDatePicker: function () {
      this.filter.date = parseInt(this.filter.date);
      this.devDate.pickerDate = this.filter.date;
      this.devDate.anotherDate = moment.unix(this.filter.date).format('DD.MM.YYYY, dd');
      this.showPicker = false;
    },
    defaultPagination() {
      this.filter.page = 1;
    },
    setTimestamp() {
      this.$store.commit('roomsServices/SelectedDatePeriod', {
        rangePeriod: {
          from: this.rangePeriod,
          to: this.rangePeriod
        },
        timestamp: this.timestamp
      });
    }
  },
};
</script>

<style lang="scss">
  .lh-16 {
    line-height: 16px;
  }
  .lh-20 {
    line-height: 20px;
  }
  .hide {
    display: none !important;
  }
  .date-btn-list, .checkbox-list {
    .radio-btn, .checkbox-btn {
      input {
        display: none;
      }
    }
    .radio-btn.active {
      background-color: $primary-light;
      border-radius: 5px;
    }
    .radio-btn > input:checked+span,
    .checkbox-btn > input:checked+span {
      // background-color: $primary-light;
    }
  }
  .radio-btn-date {
    position: relative;
    .mx-datepicker {
      width: 0;
    }
    .mx-input-wrapper {
      display: none;
    }
  }
  .room-image {
    width: 335px;
    height: 251px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
</style>
